@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }
   /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
  }
}
  
body, html, #app {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    /* user-select: none; */
}

.gradient-bg {
    background: #09203f;
    background-image: linear-gradient(to top, #09203f 0%, #537895 100%);
}

.prose {
    max-width: 100%;
    word-wrap: normal;
}


.permanent-marker-regular {
    font-family: "Permanent Marker", cursive;
    font-weight: 400;
    font-style: normal;
  }